<template>
  <div class="wait">
    <div>已成功通知{{lawyerNum}}位律师</div>
    <div class="time">
      {{counts}}<span>s</span>
    </div>
    <div>下单成功</div>
    <div>等待律师接单</div>
  </div>
</template>
<script>
  var timer = null,
    timer_lawyer = null
  export default {
    name: 'wait',
    data() {
      return {
        user: null,
        lawyerNum: 0,
        counts: '00.00',
      }
    },
    mounted() {
      this.loginIM()
      this.countFun()
      window.addEventListener("popstate", function (e) {
        clearInterval(timer)
        clearInterval(timer_lawyer)
      }, false)
    },
    methods: {
      countFun() {
        var counts = Number(this.counts),
          that = this,
          str = '00.00'
        timer = setInterval(() => {
          counts = (Number(counts) + 0.01).toFixed(2)
          if (counts >= 60) {
            clearInterval(timer)
            clearInterval(timer_lawyer)
            str = '60.00'
          } else {
            if (counts < 10) {
              str = '0' + counts
            } else {
              str = counts
            }
          }
          that.counts = str
        }, 10)
        var lawyerNum = this.lawyerNum
        timer_lawyer = setInterval(() => {
          lawyerNum = lawyerNum + parseInt(Math.random() * 3 + 1)
          that.lawyerNum = lawyerNum
        }, 1000)
      },
    },
    beforeRouteLeave(to, form, next) {
      clearInterval(timer)
      clearInterval(timer_lawyer)
      next()
    },
    components: {

    }
  }

</script>

<style scoped lang="scss">
  .wait {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #F5F5F8 url('../assets/messages_img_count.png') no-repeat;
    background-size: 100% 580px;
    text-align: center;

    >div:nth-child(1) {
      margin-top: 392px;
      font-size: 30px;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: 60px;
    }

    .time {
      width: 600px;
      height: 200px;
      background: #FFFFFF;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      box-sizing: border-box;
      padding: 10px 0;
      font-size: 160px;
      font-weight: bold;
      color: #2E8CF1;
      margin: 0 auto;
      margin-bottom: 111px;
      text-align: left;
      padding-left: 94px;

      span {
        font-size: 45px;
        font-weight: 500;
      }
    }

    >div:nth-child(3) {
      font-size: 72px;
      font-weight: 800;
      color: #333333;
      margin-bottom: 10px;
    }

    >div:nth-child(4) {
      font-size: 38px;
      font-weight: 500;
      color: #666;
    }
  }

</style>
<style>


</style>
